import React, {useRef, useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import PrivacyPolicyforJJP from "./components/privacy/PrivacyPolicyforJJP";
import PrivacyPolicyforOBH from "./components/privacy/PrivacyPolicyforOBH";
import PrivacyPolicyforSYC from "./components/privacy/PrivacyPolicyforSYC";

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import OurTeam from './pages/OurTeam';
import TermsAndCondition from './components/Terms&Condition/TermsAndCondition';
import About from './pages/About';
import PrivacyAndPolicy from './components/PrivacyAndPolicy';
import Gallery from './pages/Gallery';
import Refund from './components/Refund/Refund';
import Cancellation from './components/Cancellation/Cancellation';
import StarAward from './pages/StarAward';
import EventHoliForm from './components/EventHoliForm/EventHoliForm';
import HoliCheckoutPage from './components/HoliForm/HoliCheckoutPage.jsx'
import EnrolledSuccess from './components/EnrolledForm/EnrolledSuccess.jsx'
import EnrolledFailed from './components/EnrolledForm/EnrolledFailed.jsx'
import Verification from './components/Varefication/Verification.jsx'
import MailSuccesfullretuen from './components/MailSuccesfullretuen/MailSuccesfullretuen.jsx'
import SportsLeague from './pages/SportsLeague';
import DelhiNcr from './pages/DelhiNcr';
import Shivpuri from './pages/Shivpuri';
import Morena from './pages/Morena';

function App() {
  
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("SYADWAD YUVA CLUB");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/ourteam" element={<OurTeam />} /> 
            <Route path="/privacypolicyforJJP" element={<PrivacyPolicyforJJP />} />
            <Route path="/privacypolicyforOBH" element={<PrivacyPolicyforOBH />} /> 
            <Route path="/privacypolicyforSYC" element={<PrivacyPolicyforSYC />} /> 
            <Route path="/termsandcondition" element={<TermsAndCondition />} />
            <Route path="/privacypolicyforSYC" element={<PrivacyPolicyforSYC />} />  
            <Route path="/aboutus" element={<About/>} />  
            <Route path="/privacypolicy" element={<PrivacyAndPolicy/>} />  
            <Route path="/gallery" element={<Gallery/>} />  
            <Route path="/privacypolicy" element={<PrivacyAndPolicy/>} /> 
            <Route path="/refund" element={<Refund/>} />   
            <Route path="/cancellation" element={<Cancellation/>} /> 
            <Route path="/StarAward" element={<StarAward/>} /> 
            <Route path='/Rangotsav' element={<EventHoliForm/>} />
            <Route path='/sportsLeague' element={<SportsLeague/>}/>
            <Route path='/delhi' element={<DelhiNcr/>} />
            <Route path='/morena' element={<Morena/>} />
            <Route path='/shivpuri' element={<Shivpuri/>} />
            <Route path="/checkout" element={<HoliCheckoutPage/>}></Route>
            <Route path="/EnrolledSuccess" element={< EnrolledSuccess/>}></Route>
            <Route path="/EnrolledFailed" element={< EnrolledFailed/>}></Route>
            <Route path="/Success" element={<Verification/>}></Route>
            <Route path="/mailsend" element={<MailSuccesfullretuen/>}></Route>
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
