import React from 'react'
import "./Cancellation.css"
import NavBar from '../Navbar/NavBar'
import Footer from '../Footer'

function Cancellation() {
  return (
    <div>
    <div><NavBar/></div>
    <div className="Cancellation-maincontainer">
    <div className="CancellationPolicy-Heading">
        <h1 className="Policy-Heading">Being an NGO their is no cancellation policy</h1>
    </div>
    </div>

        <div className='bottom'><Footer/></div>
    
    </div>
  )
}

export default Cancellation