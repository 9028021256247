// Loader.js
import React from 'react';
import './Loader.css'; //hsgdfuawjhguyugu

const Loader = () => {
  return (
    <div className="custom-loader">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
