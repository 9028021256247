import React from 'react'
import {Gallery_List} from '../../assets/assets'

function GalleryInfo() {
  return (
    <div>
        <div className='w-full bg-gray-100 flex justify-center gap-10 flex-wrap' style={{marginTop:"90px"}}>
            {
                Gallery_List.map((item, index)=>(
                    <div key={index} className='text-center bg-white gap-5 p-8 my-3 mx-2 flex-wrap max-w-xs rounded-lg shadow-xl'>
                        <div><img src={item.img} className='w-max'/></div>
                        <div><p>{item.heading}</p></div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default GalleryInfo