import React from 'react'
import "./PrivacyAndPolicy.css";
import NavBar from './Navbar/NavBar';
import Footer from './Footer';

function PrivacyAndPolicy() {
  return (
    <div>
        <div><NavBar/></div>
        <div className="PrivacyAndPolicy-container">
        <section className="Privacy">
  <div className="container">
    <h1 style={{color:"#d2691e"}}><b>Privacy Policy</b></h1>
    <h2>
      <b>Introduction</b>{" "}
    </h2>
    <p>
      Welcome to Syadwadyuva Club(NGO). We value your privacy and are committed to
      protecting your personal information. This Privacy Policy explains how we
      collect, use, disclose, and safeguard your information when you visit our
      website <span style={{color:"#d2691e"}}> syadwadyuvaclub.org</span> and use our services.
    </p>
    <h1 style={{color:"#d2691e"}}>
      <b>Information We Collect
      </b>
      </h1>
    <h2>
      <b> Personal Information</b>
    </h2>
    <p>
      {" "}
      We may collect personal information that you voluntarily provide to us,
      including but not limited to:
    </p>
    <p />
    <li>
      <b> Contact Information</b> : Name, email address, phone number
    </li>
    <li>
      <b>Membership Details </b>: Information related to your membership or
      participation in our events{" "}
    </li>
    <li>
      <b>Feedback </b>: Comments or reviews you provide{" "}
    </li>
    <p />
    <h2>
      <b> Non-Personal Information </b>
    </h2>
    <p>
      We may also collect non-personal information that does not directly
      identify you but helps us understand how our website is used:
    </p>
    <p />
    <li>
      {" "}
      <b>Usage Data </b>: IP address, browser type, operating system, referring
      URLs, pages visited, and time spent on the site{" "}
    </li>
    <li>
      {" "}
      <b>Cookies </b>: We use cookies to enhance your experience on our site.
      Cookies are small data files stored on your device that help us remember
      your preferences and improve site functionality.
    </li>
    <p />
    <h1 style={{color:"#d2691e"}}><b>How We Use Your Information</b></h1>
    <p>We use your information for the following purposes:</p>
    <p />
    <li>
      {" "}
      <b>To Provide and Improve Our Services</b>: To respond to inquiries,
      process memberships, and improve our website and services.{" "}
    </li>
    <li>
      {" "}
      <b>To Communicate with You</b>: To send you updates, newsletters, and
      information about events or changes to our policies.{" "}
    </li>
    <li>
      {" "}
      <b>To Personalize Your Experience</b>: To tailor content and
      recommendations based on your interests and preferences.{" "}
    </li>
    <li>
      {" "}
      <b>To Analyze Usage</b>: To understand how our website is used and to
      enhance user experience.
    </li>
    <p />
    <h1 style={{color:"#d2691e"}}><b>Sharing Your Information</b></h1>
    <p>
      We do not sell, trade, or otherwise transfer your personal information to
      outside parties except in the following cases:
    </p>
    <p />
    <li>
      <b>Service Providers</b>: We may share your information with third-party
      service providers who assist us in operating our website, conducting our
      business, or servicing you, as long as they agree to keep this information
      confidential.
    </li>
    <li>
      {" "}
      <b>Legal Requirements </b> : We may disclose your information if required
      by law or to protect the rights, property, or safety of Syadwadyuva Club(NGO),
      our users, or others.
    </li>
    <p />
    <h1 style={{color:"#d2691e"}}><b>Security</b></h1>
    <p>
      We implement a variety of security measures to maintain the safety of your
      personal information. However, no method of transmission over the Internet
      or method of electronic storage is 100% secure. While we strive to protect
      your information, we cannot guarantee its absolute security.{" "}
    </p>
    <h1 style={{color:"#d2691e"}}><b>Your Choices</b></h1>
    <p />
    <li>
      <b>Access and Update </b> : You may access and update your personal
      information by contacting us directly.
    </li>
    <li>
      {" "}
      <b>Opt-Out</b>: You can opt out of receiving promotional emails from us by
      following the unsubscribe instructions included in those emails.
    </li>
    <li>
      {" "}
      <b>Cookies</b>: You can set your browser to refuse cookies or to alert you
      when cookies are being sent. However, disabling cookies may affect your
      ability to use certain features of our website.
    </li>
    <p />
    <h1 style={{color:"#d2691e"}}><b>Third-Party Links</b></h1>
    <p>
      {" "}
      Our website may contain links to third-party sites. We are not responsible
      for the privacy practices or content of these external sites. We encourage
      you to review the privacy policies of any third-party sites you visit.
    </p>
    <h1 style={{color:"#d2691e"}}><b>Changes to This Privacy Policy</b></h1>
    <p>
      {" "}
      We may update this Privacy Policy from time to time. We will notify you of
      any significant changes by posting the new policy on our website. Your
      continued use of the site after changes to the policy constitutes your
      acceptance of the revised terms.
    </p>
    <h1 style={{color:"#d2691e"}}><b>Contact Us</b></h1>
    <p>
      {" "}
      If you have any questions or concerns about this Privacy Policy, please
      contact us at:
    </p>
    <p>
      {" "}
      <span style={{color:"#d2691e"}}><b>Syadwadyuva Club(NGO)</b></span>
    </p>
    <p>
      <b>Email</b>: <span style={{color:"#d2691e"}}>info@syadwadyuvaclub.org</span>{" "}
    </p>
  </div>
</section>
        </div>
        <div className='bottom'><Footer/></div>
    </div>
  )
}

export default PrivacyAndPolicy