import React from 'react'
import NavBar from '../components/Navbar/NavBar'
import Footer from '../components/Footer'
import AboutInfo from '../components/AboutInfo/AboutInfo'

function About() {
  return (
   <>
    <div>
        <NavBar/>
        <AboutInfo/>
        <Footer/>
    </div>
   </>
  )
}

export default About