import React from 'react'
import style from './MailSuccesfullretuen.module.css'
import {assets} from '../../assets/assets';
import { RxCross2 } from "react-icons/rx";
import { MdAttachEmail } from 'react-icons/md';

function MailSuccesfullretuen() {
  return (
    <div style={{ width: '100%' }}>
    <div style={{width:"100%"}}>
              <div className={style.mainhead}>
                
                 
              </div> 
       </div>
       <div className={style.bodyimage}>
             
              </div>

              <div className={style.cardcontainer2}>
                <div className={style.box2}>
                     <div className={style.Errortick}> <MdAttachEmail /></div><br></br>
                
                    <h4> Please Check Your Mail , Ticket Sent On your registered E-mail Id
                   </h4>
                </div>
                </div>

              <div className={style.Footerpart}>Developed By NPRSSPL Version Code : 1.0.1 </div>
    </div>
  )
}

export default MailSuccesfullretuen;