import React from 'react'
import {About_List} from '../../assets/assets'

function AboutInfo() {
  return (
    <>
    <div style={{width:"100%", backgroundColor:"#F5F5F5", marginBottom:"-50px"}}>
    <div style={{width:"100%", textAlign:"center", marginTop:"90px"}}>
        <h1 style={{fontSize:"28px", lineHeight:"36PX", fontWeight:"700"}} className='hover:text-orange-500 text-orange-900'>SYADWAD YUVA CLUB (NGO)</h1>
    </div>
    <div style={{width:"100%", textAlign:"center"}}>
        <h2 style={{color:"rgb(120 53 15)", fontWeight:"600", fontSize:"24px"}}>An organization that gives equal respect to the views of others.</h2>
    </div>
    <div style={{display:"flex", flexDirection:"column", margin:"50px 50px"}}>
        <div>
        <h2 style={{ fontWeight:"700", fontSize:"28px"}} className='hover:text-orange-500 text-orange-900'>Syadwad Yuva Club (NGO)</h2>
        </div>
        <div>
            <p style={{color:"rgb(15 23 42)", fontWeight:"600", marginBottom:"50px"}}>Syadwad is an organization which always boost for the unity in society. Which believes in spreading the message of Lord Mahavir to the people. Syadwad is a thought that explains this. That as you are saying, understanding or doing. Not only that is right, but someone else's opinion and understanding can also be right. "An organization that gives equal respect to the views of others." "Syadwad Youth Club (NGO)" is a boon for those people who want to give a golden and secure future to religion and society but due to some reason they are unable to do so. This organization provides an opportunity to such people to make their mark in the society. Does it. Good thinking is the mother of good work. And good work is the result of good thinking.Today more than 1000 youth are providing their services in this organization and their number is continuously increasing.</p>
        </div>
    </div>
    <div style={{display:"flex", gap:"30px", justifyContent:"center", flexWrap:"wrap"}}>
        {
            About_List.map((item ,index)=>(
                <div key={index} className="hover:bg-orange-500 bg-white" style={{ padding:"20px", borderRadius:"10px", width:"300px",margin:"30px 0", boxShadow:" 0 25px 50px -12px rgba(0, 0, 0, .25)"}}>
                    <div><img src={item.img} style={{width:"350px"}}/></div>
                    <div style={{textAlign:"center", fontSize:"24px", fontWeight:"700"}}>{item.heading}</div>
                    <div style={{}}>{item.subHead}</div>
                </div>
            ))
        }
    </div>
    {/* <div style={{display:"flex", flexDirection:"column", margin:"50px 50px"}}>
        <div>
        <h2 style={{ fontWeight:"700", fontSize:"28px"}} className='hover:text-orange-500 text-orange-900'>Syadwad Yuva Club (NGO)</h2>
        </div>
        <div>
            <p style={{color:"rgb(15 23 42)", fontWeight:"600", marginBottom:"50px"}}>Syadwad is an organization whose thinking is above the thinking of Digambara, Swetambar, Sthanakavasi, Beas Panthi, Terah Panthi. Which believes in spreading the message of Lord Mahavir to the people. Syadwad is a thought that explains this. That as you are saying, understanding or doing. Not only that is right, but someone else's opinion and understanding can also be right. "An organization that gives equal respect to the views of others." "Syadwad Youth Club (NGO)" is a boon for those people who want to give a golden and secure future to religion and society but due to some reason they are unable to do so. This organization provides an opportunity to such people to make their mark in the society. Does it. Good thinking is the mother of good work. And good work is the result of good thinking.Today more than 1000 youth are providing their services in this organization and their number is continuously increasing.</p>
        </div>
    </div> */}
    </div>
    </>
  )
}

export default AboutInfo