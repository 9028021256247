import React from 'react'
import "./TermsAndCondition.css";
import NavBar from '../Navbar/NavBar';
import Footer from '../Footer';


function TermsAndCondition() {
  return (
    <>
    <div><NavBar/></div>
    <div className="Termsandcondition-maincontainer">
    <div className= "Heading-Termscondition">
    <h1>Terms and Conditions</h1>

<h2><b>Introduction</b></h2>
<p>Welcome to Syadwadyuva Club(NGO). These Terms and Conditions govern your use of our website <span style={{color:"#d2691e"}}>syadwadyuvaclub.org </span> and the services we offer. By accessing or using our website, you agree to comply with and be bound by these terms. If you do not agree with these terms, please do not use our website.</p>

<h1>Use of Our Website</h1>
<ul>
  <li><b>1.Eligibility</b>: You must be at least 18 years old or have the consent of a parent or guardian to use our website.</li>
  <li><b>2.Account Responsibility</b>: If you create an account with us, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
  <li><b>3.Prohibited Activities</b>: You agree not to engage in any activities that:</li>
</ul>
<ul className="circle-list">
  <li>Violate any applicable laws or regulations.</li>
  <li>Infringe on the intellectual property rights of others.</li>
  <li>Harass, abuse, or harm others.</li>
  <li>Disrupt or interfere with the functioning of our website or services.</li>
</ul>


<h1>Intellectual Property</h1>
<ul>
  <li><b>Ownership</b>: All content on our website, including text, graphics, logos, and images, is the property of Syadwadyuva Club(NGO) or its licensors and is protected by intellectual property laws.</li>
  <li><b>License</b>: You are granted a limited, non-exclusive, and non-transferable license to access and use our website for personal, non-commercial purposes. You may not copy, modify, distribute, or create derivative works based on our content without our prior written consent.</li>
</ul>

<h1>Limitation of Liability</h1>
<ul>
  <li><b>Disclaimer of Warranties</b>: Our website and services are provided on an "as-is" and "as-available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation or availability of our website or the accuracy of the information provided.</li>
  <li><b>Limitation of Liability</b>: To the fullest extent permitted by law, Syadwadyuva Club(NGO) shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our website or services.</li>
</ul>

<h1>Indemnification</h1>
<p>You agree to indemnify, defend, and hold harmless Syadwadyuva Club(NGO), its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses (including legal fees) arising out of or related to your use of our website or violation of these Terms and Conditions.</p>

<h1>Third-Party Links</h1>
<p>Our website may contain links to third-party websites that are not owned or controlled by Syadwadyuva Club(NGO). We are not responsible for the content or practices of any third-party sites. We recommend reviewing the terms and privacy policies of any third-party websites you visit.</p>

<h1>Changes to These Terms</h1>
<p>We reserve the right to modify or update these Terms and Conditions at any time. Any changes will be posted on our website with an updated effective date. Your continued use of our website after any changes constitutes your acceptance of the revised terms.</p>

<h1>Termination</h1>
<p>We may terminate or suspend your access to our website and services at our sole discretion, without notice, for any reason, including if we believe you have violated these Terms and Conditions.</p>

<h1>Governing Law</h1>
<p>These Terms and Conditions shall be governed by and construed in accordance with the laws of <b>India</b>, without regard to its conflict of law principles.</p>

<h1>Contact Us</h1>
<p>If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
<p><span style={{ color: "#d2691e"}}>Syadwadyuva Club(NGO)</span></p>
<p>
  <b>Email</b>: <span style={{ color: "#d2691e" }}>info@syadwadyuvaclub.org</span>
</p>

</div>
    </div>
    <div><Footer/></div>
    </>
    
  )
}

export default TermsAndCondition