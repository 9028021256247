import React from 'react'
import style from './EnrolledFailed.module.css'
import {assets} from '../../assets/assets';
import { RxCross2 } from "react-icons/rx";

function EnrolledFailed() {
  return (
    <div style={{ width: '100%' }}>
    <div style={{width:"100%"}}>
              <div className={style.mainhead}>
                
                  {/* <div className={style.lefthead}>
                    <div><img src={assets.image}/></div>
                    <div className={style.headbold}><p style={{fontWeight:"700"}}>SYADWAD YUVA CLUB</p><p>(स्याद्वाद युवा क्लब)
                    </p><p className={style.headingpart}>SYC Holi Bash 2025</p></div>
                  </div> */}
              </div> 
       </div>
       <div className={style.bodyimage}>
              {/* <div className={style.backgroundimage}><img src={assets.image3}/></div> */}
              {/* <div className={style.cardcontainer}>
                
              </div> */}
              </div>

              <div className={style.cardcontainer2}>
                <div className={style.box2}>
                     <div className={style.Errortick}><RxCross2 /></div><br></br>
                
                    <h4> Sorry , Please Check The Information you entered and try the Payment Again . 
                   </h4>
                </div>
                </div>

              <div className={style.Footerpart}>Developed By NPRSSPL Version Code : 1.0.1 </div>
    </div>
  )
}

export default EnrolledFailed