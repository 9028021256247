import sycLogo from './syclogo.png'
import image from './image 1.png'
import image3 from './Holi.png'
import image4 from './SYC RANGUTSAV 2025.png'

export const assets = {image, image3, image4};

export const About_List =[
    {img:sycLogo,
        heading:"Religious Activities",
        subHead:"584+ Weekly Abhishek, World wide Theerth Yatras " 
       },
       {img:sycLogo,
        heading:"Education Sponserships",
        subHead:"Syadwad Jain Academy"
       },
       {img:sycLogo,
        heading:"Old Age Home",
        subHead:"Sanmati Chhaya"
       },
       {img:sycLogo,
        heading:"Charitable Health Services",
        subHead:"Health Camps, Blood Donations"
       },
       {img:sycLogo,
        heading:"Sports Championships",
        subHead:"Syadwad Sports League"
       },
]

export const Gallery_List =[
    {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/16.jpeg",
        heading:"Weekly Abisheks",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
    {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/1.jpg",
     heading:"Social Achievements",
     subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
    },
    // {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/2.jpg",
    //     heading:"Social Achievements",
    //     subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
    //    },
    //    {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/3.JPG",
    //     heading:"Social Achievements",
    //     subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
    //    },
    //    {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/4.JPG",
    //     heading:"Social Achievements",
    //     subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
    //    },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/5.JPG",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/6.jpeg",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/7.JPG",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/8.jpg",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/9.jpg",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/10.jpg",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/11.jpg",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
    //    {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/12.jpg",
    //     heading:"Social Achievements",
    //     subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
    //    },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/13.JPG",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/14.JPG",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/15.jpeg",
        heading:"Social Achievements",
        subHead:"•Establishment and operation of Bridhashram in Trilok Teerth Dham"
       },
       {img:"https://storage.googleapis.com/membersdata/syc/banners/website%20photo/3.JPG",
        heading:"Mr. Shailesh Jain",
        subHead:"National President"
       },
]