


// import React, { useEffect, useState } from 'react';
// import style from './EnrolledSuccess.module.css';
// import { TiTickOutline } from 'react-icons/ti';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// function EnrolledSuccess({ razorpay_order_id1, razorpay_signature1, razorpay_payment_id1, Reciept1 }) {
//   const [status, setStatus] = useState(null);
//   const navigate =useNavigate()
//   console.log("1",Reciept1);
//   console.log("2",razorpay_signature1);
//   console.log("#", razorpay_order_id1);
//   console.log("4",razorpay_payment_id1);
  
//   useEffect(() => {
//     const fetchPaymentStatus = async () => {
//       try {
//         const response = await axios.post('https://sycmainapp.as.r.appspot.com/syc/getrrazorstatus', {
//           orderId: razorpay_order_id1,
//           payment_id: razorpay_payment_id1,
//           signature: razorpay_signature1,
//           userid: Reciept1,
//         });

//         if (response.status === 200) {
//           navigate("/EnrolledSuccess")
//         } 
//         else {
//           navigate("/EnrolledFailed")
//         }
//       } catch (error) {
//         setStatus('failure');
//         console.error('Error fetching payment status:', error);
//       }
//     };

//     fetchPaymentStatus();
//   }, [razorpay_order_id1, razorpay_signature1, razorpay_payment_id1, Reciept1]);

//   return (
//     <div style={{ width: '100%' }}>
//       <div style={{ width: '100%' }}>
//         <div className={style.mainhead}></div>
//       </div>
//       <div className={style.bodyimage}></div>

//       {/* <div className={style.cardcontainer}>
//         <div className={style.box}>
//           <div className={style.correctiontick}>
//             <TiTickOutline />
//           </div>
//           <br />
//           {status === 'success' ? (
//             <h1>Thank You for your payment, Your passes will be sent to your registered email</h1>
//           ) : status === 'failure' ? (
//             <h1>Payment verification failed. Please contact support.</h1>
//           ) : (
//             <h1>Verifying payment...</h1>
//           )}
//         </div>
//       </div> */}
//           <div style={{ width: '100%' }}>
//     <div style={{width:"100%"}}>
//               <div className={style.mainhead}>
                
                 
//               </div> 
//        </div>
//        <div className={style.bodyimage}>
             
//               </div>

//               <div className={style.cardcontainer}>
//                 <div className={style.box}>
//                      <div className={style.correctiontick}><TiTickOutline /></div><br></br>
                
//                     <h1>Thank You for your payment, Your passes will be sent to your registered email</h1>
//                 </div>
//                 </div>

//               <div className={style.Footerpart}>Developed By NPRSSPL Version Code : 1.0.1 </div>
//     </div>

//       <div className={style.Footerpart}>Developed By NPRSSPL Version Code: 1.0.1</div>
//     </div>
//   );
// }

// export default EnrolledSuccess;


import React from 'react'
import style from './EnrolledSuccess.module.css'
import {assets} from '../../assets/assets';
import { TiTickOutline } from "react-icons/ti";
import Loader1 from '../Loader/Loader';

function EnrolledSuccess({ razorpay_order_id11,razorpay_signature11, Responsestatus1,razorpay_payment_id11,Reciept11}) {

  const responsData = Responsestatus1?.success
  console.log("responsData",responsData);
  return (
    <div style={{ width: '100%' }}>
    <div style={{width:"100%"}}>
              <div className={style.mainhead}>
                
              </div> 
       </div>
       <div className={style.bodyimage}>
             
              </div>

              <div className={style.cardcontainer}>
                <div className={style.box}>
                     <div className={style.correctiontick}><TiTickOutline /></div><br></br> <br />
                
                    <h1>Thank You for your payment, Your passes will be sent to your registered email </h1>
                </div>
                </div>

              {/* <div className={style.Footerpart}>Developed By NPRSSPL Version Code : 1.0.1 </div> */}
    </div>
  )
}

export default EnrolledSuccess;


// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import style from './EnrolledSuccess.module.css';
// import { TiTickOutline } from "react-icons/ti";
// import Loader1 from '../NewForm/Loader1';

// function EnrolledSuccess({ razorpay_order_id11, razorpay_signature11, Responsestatus1, razorpay_payment_id11, Reciept11 }) {
//   const navigate = useNavigate();
//  console.log(Responsestatus1);

//  const razorpay_payment_id1 =razorpay_payment_id11
//  const  razorpay_order_id1 =  razorpay_order_id11
//  console.log("razorpay_payment_id17",razorpay_payment_id1);
//  console.log("razorpay_order_id17",razorpay_order_id1);
//  console.log("iu",Responsestatus1);
//   useEffect(() => {
//     const sendPaymentData = async () => {
//       try {
//         const response = await axios.post('https://sycmainapp.as.r.appspot.com/syc/createholiticket', {
//           success: true,
//           payment_id: razorpay_payment_id1,
//           order_id: razorpay_order_id1
//         });

//         console.log("API Response:", response.data);

//         if (response.status === 200 && response.data.success) {
//           navigate('/mailsend');
//         } else {
//           navigate('/EnrolledFailed');
//         }
//       } catch (error) {
//         console.error("API Error:", error);
//         navigate('/EnrolledFailed');
//       }
//     };

//     sendPaymentData();
//   }, [razorpay_order_id11, razorpay_payment_id11, navigate]);

//   return (
//     <div style={{ width: '100%' }}>
//       <div style={{ width: "100%" }}>
//         <div className={style.mainhead}></div>
//       </div>
//       <div className={style.bodyimage}></div>

//       <div className={style.cardcontainer}>
//         <div className={style.box}>
//           <div className={style.correctiontick}><TiTickOutline /></div><br /><br />
//           <h1>Thank You for your payment, Your passes will be sent to your registered email <Loader1 /></h1>
//         </div>
//       </div>

//       <div className={style.Footerpart}>Developed By NPRSSPL Version Code : 1.0.1</div>
//     </div>
//   );
// }

// export default EnrolledSuccess;
