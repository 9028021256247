import React from 'react'
import NavBar from '../components/Navbar/NavBar'
import Footer from '../components/Footer'

export default function Morena() {
  return (
    <div>
        <NavBar/>
        <br/>
        <br/>
        <br/>
        <Footer/>
      
    </div>
  )
}
