


// import React, { useEffect, useState } from 'react';

// import { TiTickOutline } from 'react-icons/ti';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import Loader from '../NewForm/Loader';

// import style from './Verification.module.css'
// import {assets} from '../../assets/asset';
// import Loader1 from '../NewForm/Loader1';
// import EnrolledSuccess from '../EnrolledForm/EnrolledSuccess';


// function Verification({ razorpay_order_id1, razorpay_signature1, razorpay_payment_id1, Reciept1 }) {
//   const [status, setStatus] = useState(null);
//   const [Responsestatus, setResponsStatus] = useState("");
//   console.log("Responsestatus",Responsestatus);
//   console.log("status",Responsestatus?.success);
//   const navigate =useNavigate()
//   console.log("1",Reciept1);
//   console.log("2",razorpay_signature1);
//   console.log("#", razorpay_order_id1);
//   console.log("4",razorpay_payment_id1);
//   const fetchPaymentStatus = async () => {
//     try {
//       const response = await axios.post('https://sycmainapp.as.r.appspot.com/syc/getrrazorstatus', {
//         orderId: razorpay_order_id1,
//         payment_id: razorpay_payment_id1,
//         signature: razorpay_signature1,
//         userid: Reciept1,
//       });
//       console.log("12",response);
//       setResponsStatus(response?.data)

//       if (response.status === 200) {
   
//       navigate("/EnrolledSuccess")
   
//       }  
//       else if(response.status === 403){
     
//           navigate("/EnrolledFailed")
       
//     } }
//     catch (error) {
//       setStatus('failure');
//       setTimeout(()=>{
//         navigate("/EnrolledFailed")
//        },4000)
//       console.error('Error fetching payment status:', error);
//     }
//   };


//   useEffect(() => {
//     fetchPaymentStatus();
   
//   }, [razorpay_order_id1, razorpay_signature1, razorpay_payment_id1, Reciept1]);

//   if(Responsestatus.success === true  )
//     return( <EnrolledSuccess razorpay_order_id11={razorpay_order_id1  }  Responsestatus1={Responsestatus} razorpay_signature11={razorpay_signature1} razorpay_payment_id11={razorpay_payment_id1} Reciept11={Reciept1}   ></EnrolledSuccess>)

//   return (
//     <div style={{ width: '100%' }}>
//     <div style={{width:"100%"}}>
//               <div className={style.mainhead}>
                
//               </div> 
//        </div>
//        <div className={style.bodyimage}>
//               {/* <div className={style.backgroundimage}><img src={assets.image3}/></div> */}
//               {/* <div className={style.cardcontainer}>
                
//               </div> */}
//               </div>

//               <div className={style.cardcontainer}>
//         <div className={style.box}>
//         <h1 style={{ fontWeight: 950, marginbuttom:"40px" }}>Verifying your payment, Please wait  <div className={style.spinner}><Loader1/></div></h1>

         
//         </div>
        
//       </div>

//       <div className={style.Footerpart}>
//         Developed By NPRSSPL Version Code : 1.0.1
//       </div>
//     </div>
//   );
// }

// export default Verification;


import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Loader1 from '../Loader/Loader';
import EnrolledSuccess from '../EnrolledForm/EnrolledSuccess';

import style from './Verification.module.css';

function Verification({ razorpay_order_id1, razorpay_signature1, razorpay_payment_id1, Reciept1 }) {
  const [Responsestatus, setResponsStatus] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const hasFetched = useRef(false);
  const navigate = useNavigate();

  // console.log("Responsestatus:", Responsestatus);
  // console.log("razorpay_order_id1:", razorpay_order_id1);
  // console.log("razorpay_payment_id1:", razorpay_payment_id1);
  // console.log("razorpay_signature1:", razorpay_signature1);
  // console.log("Reciept1:", Reciept1);

  const fetchPaymentStatus = async () => {
    if (hasFetched.current) return; // Prevent duplicate requests
    hasFetched.current = true; // Mark as fetched

    setIsFetching(true);
    try {
      const response = await axios.post('https://sycmainapp.as.r.appspot.com/syc/getrrazorstatus', {
        orderId: razorpay_order_id1,
        payment_id: razorpay_payment_id1,
        signature: razorpay_signature1,
        userid: Reciept1,
      });

      setResponsStatus(response);

      if (response.status === 200) {
        navigate("/EnrolledSuccess");
      } else if (response.status === 403) {
        navigate("/EnrolledFailed");
      }
    } catch (error) {
      console.error('Error fetching payment status:', error);
      setTimeout(() => {
        navigate("/EnrolledFailed");
      }, 4000);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (razorpay_order_id1 && razorpay_payment_id1 && razorpay_signature1 && Reciept1) {
      fetchPaymentStatus();
    }
  }, [razorpay_order_id1, razorpay_signature1, razorpay_payment_id1, Reciept1]);

  if (Responsestatus?.success=== true) {
    return (
      <EnrolledSuccess
        razorpay_order_id11={razorpay_order_id1}
        Responsestatus1={Responsestatus}
        razorpay_signature11={razorpay_signature1}
        razorpay_payment_id11={razorpay_payment_id1}
        Reciept11={Reciept1}
      />
    );
  }

  return (
    <div style={{ width: '100%'}}>
      <div className={style.mainhead}></div>
      <div className={style.bodyimage}>
        <div className={style.cardcontainer}>
          <div className={style.box}>
            <h1 style={{ fontWeight: 950 }}>
              Verifying your payment, Please wait
              <div className={style.spinner}>
                <Loader1 />
              </div>
            </h1>
          </div>
        </div>
      </div>
      <div className={style.Footerpart}>Developed By NPRSSPL Version Code : 1.0.1</div>
    </div>
  );
}

export default Verification;
