import React from 'react'
import Footer from '../components/Footer'
import NavBar from '../components/Navbar/NavBar'

export default function SportsLeague() {
  return (
    <div>
        <NavBar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer/>
      
    </div>
  )
}

