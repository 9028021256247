import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import  style from "./HoliForm.module.css"
import { assets } from "../../assets/assets";
import EnrolledSuccess from "../EnrolledForm/EnrolledSuccess";
import { useNavigate } from "react-router-dom";
import Verification from "../Varefication/Verification";
import Loader from "../Loader/Loader";

export default function HoliCheckoutPage({resdata,responseProp1 ,EmailId1  ,Ids,phonenumber1, bookingId, totalAmount }) {
  // console.log("responseProp1",responseProp1);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  // console.log("phonenumber1",phonenumber1);
  const [responseId , setResponse]= useState("")
  // console.log();
  //   console.log("responseId ",responseId );
    const razorpay_payment_id = responseId?.razorpay_payment_id
    const razorpay_signature= responseId?.razorpay_signature
    const razorpay_order_id= responseId?.razorpay_order_id
   
    const bookingId1 =bookingId
    const Id = Ids
    const EmailId =EmailId1
    const usernumber =phonenumber1
    const [Reciept , setReciept] = useState("")
    const Reciept1 = responseId?.Reciept
    // console.log("Reciept",Reciept);
    
    // console.log("EmailId" ,EmailId);
    // console.log("usernumber" ,usernumber);
    const userName =responseProp1?.data?.name 
    // console.log("userName",userName);
    // console.log(" totalAmounta", totalAmount);
    function loadScript(src) {
      return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }
        document.body.appendChild(script)
      })
    }
    const navigator =useNavigate()
  async function displayRazorpay() {
    
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    setIsOtpLoading(true)
      if (!res){
        alert('Razropay failed to load!!')
        return 
      }


    try {
      const res = await axios.post("https://sycmainapp.as.r.appspot.com/syc/createrazorpayorder", {
        amount: totalAmount,
        bookingid: Id, // Send booking ID to backend
      });

      const  order_id  = res.data?.id;
      const  Receptdata   = res.data?.receipt;
       //console.log("res.data?.id"+Receptdata);
      setReciept(Receptdata)

      // console.log("order_id",order_id );

      // const options = {
      //   key: "rzp_test_UTd7i09foT1Ag6",
      //   amount: totalAmount 
      //   *100, // Convert to paise
      //   currency: "INR",
      //   name: "SYC Holi Bash 2025",
      //   description: `Booking ID: ${bookingId}`,
      //   image: "https://syadwadyuvaclub.org/static/media/syclogo.0153035d6b208c8223b9.png",
      //   order_id: order_id,
      //   callback_url: "http://localhost:3000/EnrolledSuccess",
      //   prefill: {
      //     name: userName,
      //   email: EmailId,
      //   contact: usernumber 
         
          
      //   },
      //   handler: function (response) {
      //     console.log("Payment successful", response);
      //     setResponse(response)
  
      
          
      //     navigator("/EnrolledSuccess"); 
  
        
      // },
      //   theme: {
      //     color: "#3399cc",
      //   },
      // };
      const options = {
       //  key: "rzp_test_UTd7i09foT1Ag6",
       key: "rzp_live_832DUBTq1UpvUP",
        amount: totalAmount * 100, // Convert to paise
        currency: "INR",
        name: "SYC Rangotsav 2025",
        description: `Booking ID: ${bookingId}`,
        image: "https://syadwadyuvaclub.org/static/media/syclogo.0153035d6b208c8223b9.png",
        order_id: order_id,
        prefill: {
          name: userName,
          email: EmailId,
          contact: usernumber
        },
        handler: function (response) {
           console.log("Payment successful");
          setResponse(response);
          // ✅ Navigate without reload
         
          return <Verification Reciept1={Reciept} razorpay_payment_id1={razorpay_payment_id}  razorpay_signature1={razorpay_signature}  razorpay_order_id1 ={razorpay_order_id}/>;
          navigator("/Success"); 
        },
        theme: {
          color: "#3399cc",
        },
      };
     
  //  if ( responseId?.razorpay_payment_id ) {
  //   // console.log("response after Razorpay"+responseId);
  //     return <Verification Reciept1={Reciept} razorpay_payment_id1={razorpay_payment_id}  razorpay_signature1={razorpay_signature}  razorpay_order_id1 ={razorpay_order_id}/>;
  //   }

      
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error while initializing Razorpay:", error);
      toast.error("Failed to initialize payment");
    }
    finally{
      setIsOtpLoading(false)
    }
  }
  //console.log("response after Razorpay"+responseId);
   if ( responseId?.razorpay_payment_id ) {
    //console.log("response after Razorpay"+responseId);
      return <Verification Reciept1={Reciept} razorpay_payment_id1={razorpay_payment_id}  razorpay_signature1={razorpay_signature}  razorpay_order_id1 ={razorpay_order_id}/>;
    }
  
  //   if ( !responseId?.razorpay_payment_id) {
  //     return <HoliCheckoutPage />;
  //   }
  return (

    
          <div style={{ width: '100%' }}>
       <div style={{width:"100%"}}>
                 <div className={style.mainhead1}>
                     {/* <div className={style.lefthead}>
                       <div><img src={assets.image}/></div>
                       <div className={style.headbold}><p style={{fontWeight:"700"}}>SYADWAD YUVA CLUB</p><p>(स्याद्वाद युवा क्लब)
                       </p><p className={style.headingpart}>SYC Holi Bash 2025</p></div>
                     </div> */}
                 </div> 
          </div>
    <div className={style.checkoutpage}>
      <h2>Checkout</h2>

      <h6 className={style.address} > SYC RANGOTSAV 2025 </h6>
      <h6 className={style.address}> VENUE : Shastri Farms , Near , Kriddha Greens , Sector 128 Noida 201304 </h6>

      {/* <p>Booking ID: {bookingId}</p> */}
      <h1 className={style.amountBtn}>Total amount to pay: ₹{totalAmount}</h1>
      <div className={style.innercon2}>
      <button className={style.innercon2} 
      onClick={displayRazorpay}>{isOtpLoading? <Loader></Loader> :""} Pay Now ₹{`${totalAmount}`}</button>

      </div>
     
    </div>
    {/* <div className={style.Footerpart}>Developed By NPRSSPL Version Code : 1.0.1 </div> */}
    </div>
  );
}
